/* eslint-disable no-underscore-dangle */
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { ThemeProvider } from '@mui/material';
import MainTemplate from 'themes/MainTemplate';
import Typography from '@mui/material/Typography';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AUTH_TYPE, createAuthLink } from 'aws-appsync-auth-link';
import { ApolloClient, ApolloLink, InMemoryCache, HttpLink } from '@apollo/client';
import { ErrorResponse, onError } from '@apollo/client/link/error';

import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { ApolloProvider } from '@apollo/react-hooks';
import AppSyncConfig from 'app/AppSync';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ErrorScreen from 'components/Layout/ErrorScreen';
import { IReduxState } from 'interface/redux';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStorage, tokenKey } from 'utils/localStorageUtil';
import { Route, useLocation, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import { userCheck, userRefreshToken } from 'redux/auth/action';
import { clearToast } from 'redux/toast/action';
import { Helmet } from 'react-helmet';

import { theme } from 'themes';
import getAvailableRoutes from './routes';

function HashNavivator() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash === '') {
      window.scrollTo(0, 0);
      return;
    }

    setTimeout(() => {
      const id = location.hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);
  }, [location.pathname, location.hash, location.key]);

  return null;
}

function App() {
  const dispatch = useDispatch();
  const isRefreshed = useSelector((state: IReduxState) => state?.auth?.isRefreshed);
  const isChecked = useSelector((state: IReduxState) => state?.auth?.isChecked);
  const user = useSelector((state: IReduxState) => state?.auth?.user);
  const toast = useSelector((state: IReduxState) => state?.toast);

  const url = AppSyncConfig.graphqlEndpoint;
  const httpLink = new HttpLink({ uri: url });
  const [token, setToken] = useState(getStorage(tokenKey));

  useEffect(() => {
    setToken(getStorage(tokenKey));
  }, [user]);

  useEffect(() => {
    if (!user && !isChecked) {
      dispatch(userCheck());
    }
  }, [user]);

  const router = useMemo(() => {
    const routes = getAvailableRoutes(user?.role);

    return createBrowserRouter(
      createRoutesFromElements(
        <>
          <Route
            path="*"
            element={
              <MainTemplate>
                <ErrorScreen />
              </MainTemplate>
            }
          />
          {routes.map((item) => {
            const PageComponent = item.pageComponent;
            const Layout = item.template;
            return (
              <Route
                path={item.path}
                element={
                  <Layout>
                    <HashNavivator />
                    <PageComponent />
                  </Layout>
                }
                errorElement={
                  <MainTemplate>
                    <ErrorScreen />
                  </MainTemplate>
                }
                key={item.path}
              />
            );
          })}
        </>,
      ),
    );
  }, [user?.role]);

  const handleAuthorizeError = () => {
    if (!isRefreshed) {
      dispatch(userRefreshToken());
    }
  };

  const link = ApolloLink.from([
    onError(({ graphQLErrors, networkError }: ErrorResponse) => {
      if (graphQLErrors) {
        graphQLErrors.forEach((error: any) => {
          if (error.errorType === 'AuthorizerFailureException') {
            handleAuthorizeError();
          }
        });
      }
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    createAuthLink({
      url,
      region: AppSyncConfig.region,
      auth: {
        type: AUTH_TYPE.AWS_LAMBDA,
        token,
      },
    }),
    createSubscriptionHandshakeLink(
      {
        url,
        region: AppSyncConfig.region,
        auth: {
          type: AUTH_TYPE.AWS_LAMBDA,
          token,
        },
      },
      httpLink,
    ),
  ]);

  const client = new ApolloClient({
    link,
    cache: new InMemoryCache({
      dataIdFromObject: (obj: any) => {
        if (obj.__typename === 'File') {
          return obj.fileId;
        }
        return obj.id;
      },
    }),
  });

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{process.env.REACT_APP_NAME}</title>
            <meta name="keywords" content={process.env.REACT_APP_KEYWORDS} />
            <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
            {/* Issue with fragment https://github.com/nfl/react-helmet/issues/712 */}
            {process.env.REACT_APP_ENV === 'prod' && <meta name="msvalidate.01" content={process.env.REACT_APP_BING_SITE_VERIFICATION} />}
            {process.env.REACT_APP_ENV === 'prod' && <meta name="google-site-verification" content={process.env.REACT_APP_GOOGLE_SITE_VERIFICATION} />}
            {process.env.REACT_APP_ENV === 'prod' && <script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_TAG_MANAGER}`} />}
            {process.env.REACT_APP_ENV === 'prod' && (
              <noscript>
                {`<iframe
                      src="https://www.googletagmanager.com/ns.html?id=%REACT_APP_GTM%"
                      height="0"
                      width="0"
                      style="display:none;visibility:hidden"
                    ></iframe>`}
              </noscript>
            )}
            {process.env.REACT_APP_ENV === 'prod' && (
              <script type="text/javascript">
                {`(function(w,d,s,l,i){w[l] = w[l] || [];w[l].push({'gtm.start':
                      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${process.env.REACT_APP_GTM}');`}
              </script>
            )}
            {process.env.REACT_APP_ENV === 'prod' && (
              <script>
                {`window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${process.env.REACT_APP_TAG_MANAGER}');`}
              </script>
            )}
            {process.env.REACT_APP_ENV !== 'prod' && <meta name="robots" content="noindex, nofollow" />}
          </Helmet>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={typeof toast.type === 'string'}
            autoHideDuration={6000}
            onClose={() => {
              dispatch(clearToast());
            }}
          >
            <Alert
              onClose={() => {
                dispatch(clearToast());
              }}
              severity={toast.type ?? 'warning'}
              sx={{ width: '100%' }}
            >
              <>
                <Typography>{toast.title}</Typography>
                {toast.description}
              </>
            </Alert>
          </Snackbar>
          <RouterProvider router={router} />
        </LocalizationProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
